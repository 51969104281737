import * as React from "react";
import Layout from "../components/layout/Layout";
import Heading from "../components/ui/Heading";
import ResponsiveContainer from "../components/ui/ResponsiveContainer";
import Overview from "../components/media/Overview";

const NewsOverviewPage = () => {
  return (
    <Layout title='Neuigkeiten'>
      <ResponsiveContainer>
        <Heading firstHighlightedWord='Neuigkeiten ' heading='über Wertschätzung ' small />
        <Overview category='Neuigkeit' />
      </ResponsiveContainer>
    </Layout>
  );
};

export default NewsOverviewPage;
